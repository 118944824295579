<template>
  <div class="page-body-box body-scope">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-input
          placeholder="请输入厂家名称"
          v-model="searchData.venderInputVal"
          clearable>
        </el-input>
      </el-col>
      <el-col :span="6">
        <el-input
          placeholder="请输入直播名称"
          v-model="searchData.liveInputVal"
          clearable>
        </el-input>
      </el-col>
      <el-col :span="6">
        <el-select v-model="searchData.selectedType" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="onSearch">搜索</el-button>
      </el-col>
    </el-row>
    <div class="table-box">
      <el-table
        :data="tableData"
        border
        style="width: 100%">
        <el-table-column
          prop="live"
          min-width="180"
          label="直播频道">
        </el-table-column>
        <el-table-column
          prop="jiangzhe"
          min-width="180"
          label="播客">
        </el-table-column>
        <el-table-column
          prop="zhicheng"
          min-width="150"
          label="职称">
        </el-table-column>
        <el-table-column
          prop="feiyong"
          min-width="180"
          label="视频采购费">
        </el-table-column>
        <el-table-column
          prop="startTime"
          min-width="180"
          label="开始时间">
        </el-table-column>
        <el-table-column
          prop="endTime"
          min-width="180"
          label="结束时间">
        </el-table-column>
        <el-table-column
          prop="timeLength"
          min-width="150"
          label="视频时长">
          <template slot-scope="scope">
            <span>{{scope.row.timeLength + "分钟"}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="changjia"
          min-width="180"
          label="厂家">
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="150">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="sorter-box"
      @current-change="onCurrentChange"
      :current-page="searchData.pageNo"
      :page-size="searchData.pageSize"
      :total="total"
      background
      hide-on-single-page
      layout="total, prev, pager, next, jumper"
    ></el-pagination>
    <!-- 审核弹窗 -->
    <el-dialog title="审核" :visible.sync="isShowAuditDialog" :close-on-click-modal="false">
      <div class="live-audit-box">
        <div class="live-video-box">
          <div class="video-background">直播封面</div>
          <div class="video-title">
            <span>直播频道：</span>
            <span>骨质疏松初始治疗的一线选择</span>
          </div>
        </div>
        <div class="speaker-info-box">
          <div>
            <span>播客：</span>
            <span>王五</span>
          </div>
          <div>
            <span>职称：</span>
            <span>主治医师</span>
          </div>
          <div>
            <span>科室：</span>
            <span>儿科</span>
          </div>
          <div>
            <span>医院：</span>
            <span>温州市中西医结合医院</span>
          </div>
        </div>
        <div class="live-info-box">
          <div>
            <span>直播开始时间：</span>
            <span>2020.08.22 12：00</span>
          </div>
          <div>
            <span>直播结束时间：</span>
            <span>2020.08.22 12：00</span>
          </div>
          <div>
            <span>播客上线时间：</span>
            <span>2020.08.22 12：00</span>
          </div>
          <div>
            <span>播客下线时间：</span>
            <span>2020.08.22 12：00</span>
          </div>
          <div>
            <span>视频回放时间：</span>
            <span>14分钟</span>
          </div>
        </div>
        <div class="mgn-t20">
          <span>视频采购费：</span>
          <span>2000</span>
        </div>
        <div class="mgn-t20">
          <span>厂家：</span>
          <span>石药集团-浙江大区-杭州地区</span>
        </div>
        <div class="mgn-t20">
          <el-row :gutter="20">
            <el-col :span="4">
              <el-select v-model="auditVal" placeholder="请选择" @change="onDialogSelected">
                <el-option
                  v-for="item in auditOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="20" v-show="isShowTextarea">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                :rows="2"
                placeholder="请输入理由"
                v-model="textarea">
              </el-input>
            </el-col>
          </el-row>
        </div>
        <div class="mgn-t20 text-ct">
          <el-row :gutter="20">
            <el-col :span="6" :offset="6"><el-button @click="onClose">取消</el-button></el-col>
            <el-col :span="6"><el-button type="primary" @click="onsubmit">确认</el-button></el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name:"LiveAuditList",
  components:{
  },
  data(){
    return {
      // ------------------- selectList ---------------
      options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
        // -------------------- 搜索、页数 -----------------
        total:0,
        searchData:{
          venderInputVal:"",
          liveInputVal:"",
          selectedType: "",
          pageNo:1,
          pageSize:10,
        },
        // ------------------ tableData -----------------
        tableData: [
          {
            live:"《骨质疏松初始治疗》",
            jiangzhe:"萝卜",
            zhicheng:"主治医师",
            feiyong:"2000",
            startTime:"2020-12-11 12：00：00",
            endTime:"2020-12-11 12：00：00",
            timeLength:"10",
            changjia:"石药集团-浙江大区"
          }
        ],
        // ------------------- dialog 弹窗 -------------------
        isShowAuditDialog:false,
        auditOptions:[
          {
            value:"1",
            label:"通过"
          },
          {
            value: "2",
            label:"拒绝"
          }
        ],
        auditVal:"",
        textarea:"",
        isShowTextarea: false,
    }
  },
  methods:{
    onSearch(){
      this.$set(this.searchData,"pageNo",1)
      console.log(this.searchData,"---------------- onSearch 搜索 ----------------")
    },
    handleClick(row) {
      console.log(row);
      this.isShowAuditDialog = true;
    },
    onCurrentChange(options) {
      this.$set(this.searchData, "pageNo", options);
    },
    onDialogSelected(val){
      console.log(val,"---------- onDialogSelected ---------------");
      if(val === "1"){
        this.isShowTextarea = false;
      } else {
        this.isShowTextarea = true;
      }
    },
    onClose(){
      this.isShowAuditDialog = false;
    },
    onsubmit(){
      console.log(this.auditVal,this.textarea,'----------------- onsubmit --------------')
    }
  }
}
</script>

<style scoped>
.body-scope{
  background: #ffffff;
  padding: 30px 30px 0 30px;
}
.table-box{
  margin-top:60px;
}
.live-audit-box{
  font-weight: bold;
}
.live-video-box{

}
.video-background{
  min-width:40px;
  max-width: 400px;
  height:250px;
  background:cadetblue;
}
.video-title{
  margin-top:15px;

}
.speaker-info-box{
  margin-top:20px;
}
.live-info-box{
  margin-top: 20px;
}
.sorter-box{
  padding: 30px;
  text-align: right;
  background:#ffffff;
}
</style>
